import agent from "../lib/agent"
import React, { useEffect, useState } from 'react';
import JSONData from "../data/texts.json"
import AppLayout from "../components/layouts/app"

const ConfirmAccount = () => {
  const [notice, setNotice] = useState("Your account is being confirmed ...");

  useEffect(() => {
    const t = window.location.href.split("?")[1];
    agent.Account.confirm(t)
      .then(res => {
        localStorage.setItem("notice", JSON.stringify({
          "ok": true,
          "message": "Account successfully confirmed!"
        }))
        window.location.href = "/app";
      })
      .catch(err => {
        setNotice("The account confirmation token has expired")
      })
      .finally(() => console.log("ok"))
  }, []); // Only re-run the effect if count changes

  const page = {
    title: "Confirm Account",
    description: ""
  }
  return (
    <main >
      <title>Confirm Account</title>
      <AppLayout menu={JSONData.menu} page={page}>
        <div className="welcome-area" id="welcome">
          <div className="form-card-container app-form col-10 col-md-8 col-l-6">
            <h3 className="form-card-header">Confirm your account</h3>
            <div className="form-card-content">
              <div className="col-lg-12">
                <div className="notice">
                  <p className="text-muted text-center mb-3">
                    {notice}
                  </p>
                </div>
              </div>
            </div>
          </div> 
        </div>
      </AppLayout>
    </main>
  )
}

export default ConfirmAccount
